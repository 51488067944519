import { Grid, Paper, Typography, Divider } from '@material-ui/core'
import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { flow, map, sortBy, capitalize, uniq } from 'lodash/fp'
import SocialProfiles from '../components/SocialProfiles'
import { blue, grey } from '@material-ui/core/colors'
import Bio from '../components/Bio'
import imageOfPlaylists from './playlists-demo.png'
import { SubscriptionForm } from '../components/SubscriptionForm'

const HeaderType = ({ children }) => (
  <Typography
    variant={'overline'}
    color={'textSecondary'}
    style={{ textTransform: 'uppercase' }}
  >
    {children}
  </Typography>
)

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const countOfSubscribers =
      data.allMailchimpList.edges[0].node.stats.member_count

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="My Habits" keywords={[`blog`, `gatsby`, `habits`, `rg`]} />
        <Typography variant={'h4'} gutterBottom>
          My Habits
        </Typography>

        <Typography variant={'body2'} color={'textSecondary'} gutterBottom>
          For some reason I've manage to grow being very resilient to building
          habits. In other words I had very few of them. It's time for a change.
        </Typography>

        <br />
        <Grid container spacing={0}>
          <Grid xs={12}>
            <Typography variant={'h6'} gutterBottom>
              Music Playlists By Activity
            </Typography>
            <img
              style={{ border: `1px solid ${grey[800]}` }}
              src={imageOfPlaylists}
            />
            <br />
            <Typography gutterBottom>
              A way I found to organize my music playlists is by activity. It's
              quite convenient.
            </Typography>
            <Typography gutterBottom>
              Now and then turn on random playlist from search and pull songs
              from there into my playlists, so they grow.
            </Typography>
            <Typography gutterBottom>
              For every activity I want specific mood. For qigong it's a Chinese
              riffs and sounds of nature. For writing it's stuff without lyrics.
              For coding it is more of electronic music. And so on.
            </Typography>
            <Typography gutterBottom>
              Also it might be that those playlist refer to specific mood. Less
              energy, more energy, less boosting, more boosting playlists.
            </Typography>
            <Typography gutterBottom>It works okay.</Typography>
          </Grid>
          <Grid xs={12}>
            <br />
            <Typography variant={'h6'} gutterBottom>
              Meditating Before Sleep
            </Typography>
            <Typography gutterBottom>...</Typography>
            <br />
            <Typography variant={'h6'} gutterBottom>
              Evening walk
            </Typography>
            <Typography gutterBottom>...</Typography>
            <br />
            <Typography variant={'h6'} gutterBottom>
              Clean More Than I Mess
            </Typography>
            <Typography gutterBottom>
              The Only Working Way To Deal with a Mess
            </Typography>
            <Typography gutterBottom>
              Every time I add a new bit of mess I should clean it and take away
              from the previous debt. If I've used a dish — I should clean two
              dishes. If I picking up a pair of socks, I should pick two of
              them. Etc.
            </Typography>
          </Grid>
        </Grid>
        <br />
        <br />
        <Typography variant={'h6'} gutterBottom color={'textSecondary'}>
          To be continued...
        </Typography>
        <br />
        <br />
        <Bio />
        <br />
        <br />
        <Typography color={'textSecondary'} variant={'overline'}>
          Connect
        </Typography>
        <SocialProfiles />
        <br />
        <br />
        <Typography gutterBottom variant={'overline'} color={'textSecondary'}>
          Subscribe!
        </Typography>
        <SubscriptionForm countOfSubscribers={countOfSubscribers} />
        <br />
        <br />
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMailchimpList {
      edges {
        node {
          id
          stats {
            member_count
          }
        }
      }
    }
  }
`
